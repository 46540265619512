<template>
	<ion-page>
		<ion-header :translucent="true">
			<ion-toolbar>
				<ion-buttons slot="start">
					<ion-menu-button color="black"></ion-menu-button>
				</ion-buttons>
				<ion-img :src="require('../assets/img/logo-text.svg')" />
			</ion-toolbar>
		</ion-header>

		<ion-content
			:fullscreen="true"
			class="inset-container ion-padding-bottom"
		>
			<div id="greeting" class="hidden-lg-up">
				<div class="container">
					<h1 class="slabs">
						Wunderschönen {{ greeting }},
						{{ $store.state.user.first_name }}!
					</h1>
				</div>
			</div>

			<div class="container ion-padding">
				<h2 v-if="progressKurse != ''">Weiter geht´s</h2>
				<h2 v-else>Aktuelle Kurse</h2>
				<div class="divider"></div>
			</div>
			<ion-grid>
				<ion-row>
					<ion-col>
						<kurs-card
							:kurs="kurs"
							progress="0"
							v-for="kurs in allKurse"
							:key="kurs.id"
						></kurs-card>
					</ion-col>
				</ion-row>
			</ion-grid>

			<div class="container ion-padding">
				<ion-button
					expand="block"
					fill="outline"
					color="black"
					router-link="/kurse"
				>
					Alle Kurse
				</ion-button>
			</div>

			<div class="container ion-padding">
				<h2>Aktuelles</h2>
				<div class="divider"></div>
			</div>
			<ion-grid>
				<ion-row>
					<ion-col
						v-for="news in allNews"
						size="12"
						size-sm="6"
						size-xl="4"
						:key="news.id"
					>
						<news-card :news="news"></news-card>
					</ion-col>
				</ion-row>
			</ion-grid>
			<div class="container ion-padding tabbar-b-margin">
				<ion-button
					expand="block"
					fill="outline"
					color="black"
					router-link="/news"
				>
					Alle Neuigkeiten
				</ion-button>
			</div>
		</ion-content>

		<TabBar></TabBar>
	</ion-page>
</template>


<script>
import {
	IonContent,
	IonPage,
	IonGrid,
	IonRow,
	IonCol,
	IonButton,
	IonButtons,
	IonHeader,
	IonMenuButton,
	IonToolbar,
	IonImg,
} from '@ionic/vue';

import KursCard from '@/components/KursCard.vue';
import NewsCard from '@/components/NewsCard.vue';
import TabBar from '@/components/TabBar.vue';

export default {
	name: 'Start',
	components: {
		IonContent,
		IonPage,
		KursCard,
		NewsCard,
		TabBar,
		IonGrid,
		IonRow,
		IonCol,
		IonButton,
		IonButtons,
		IonHeader,
		IonMenuButton,
		IonToolbar,
		IonImg,
	},
	computed: {
		greeting() {
			const myDate = new Date();
			const hrs = myDate.getHours();

			let greet = '';

			if (hrs < 12) greet = 'guten Morgen';
			else if (hrs >= 12 && hrs <= 17) greet = 'guten Tag';
			else if (hrs >= 17 && hrs <= 24) greet = 'guten Abend';

			return greet;
		},
	},
	data() {
		return {
			allNews: [],
			allKurse: [],
			progressKurse: [],
			doneKurse: [],
			onboardingKursID: null,
		};
	},
	methods: {
		async ionViewWillEnter() {
			// Queries /////////////
			// we have 3 slots for courses
			// First place is onboarding or newest in progress or newest not done
			// Second place is newest not done or newest in progress if no advertorial
			// 3rd place is advertorial or newest not done

			this.progressKurse = [];
			this.doneKurse = [];
			this.allKurse = [];

			// Find IDs of started and finished courses
			for (let kurs in this.$store.state.user.progress) {
				if (
					kurs != 'undefined' &&
					this.$store.state.user.progress[kurs] &&
					!this.$store.state.user.progress[kurs].done &&
					kurs !== this.onboardingKursID
				)
					this.progressKurse.push(kurs);
				else if (this.$store.state.user.progress[kurs].done)
					this.doneKurse.push(kurs);
			}

			// Set filter for untouched courses
			const filterProgressAndDone = [];
			if (this.progressKurse != '')
				filterProgressAndDone.push(
					`{"id":{"_nin":[${this.progressKurse}]}}`
				);
			if (this.doneKurse != '') {
				filterProgressAndDone.push(
					`{"id":{"_nin":[${this.doneKurse}]}}`
				);
			}

			// Fetch onboarding, advertorials, progress kurs queries
			const kursBase = '/items/kurse?fields=*';
			const onboardingCall =
				kursBase + '&filter[onboarding]=true&limit=1';
			const advertorialCall = kursBase + '&filter[advertorial]=true';

			// Fetch newest kurs queries
			const newestFilter = filterProgressAndDone.length
				? `[{"advertorial":{"_eq": false}},${filterProgressAndDone.toString()}]`
				: `[{"advertorial":{"_eq": false}}]`;
			const newestCall =
				kursBase + `&sort=-id&filter={"_and":${newestFilter}}&limit=3`;

			// Get news overview query
			let newsUrl =
				'/items/news?limit=3&fields=*,bild.*,kategorie.*&sort=-featured,-date_created';

			// query progress kurse if we have any
			let startedKurse = [];
			if (this.progressKurse != '') {
				const progressKurse = await this.$http.get(
					kursBase +
						`&sort=-id&filter={"id":{"_in":[${this.progressKurse}]}}&limit=2`
				);
				startedKurse = progressKurse.data.data;
			}

			const [
				onboardingKursData,
				advertorialKurseData,
				newestKurseData,
				newsData,
			] = await Promise.all([
				this.$http.get(onboardingCall),
				this.$http.get(advertorialCall),
				this.$http.get(newestCall),
				this.$http.get(newsUrl),
			]);

			const onboardingKurs = onboardingKursData.data.data;
			const advertorialKurse = advertorialKurseData.data.data;
			const newestKurse = newestKurseData?.data?.data || [];
			const news = newsData.data.data;

			// NEWS /////////////
			// Additionally sort featured by sort
			this.allNews = news.sort((a, b) => {
				if (a.featured && b.featured) return a.sort - b.sort;
				return true;
			});

			// KURSE /////////////
			// Onboarding Kurs Logic
			// Find out onboarding kurs id
			let onboarding = null;
			this.onboardingKursID = null;
			if (onboardingKurs[0])
				this.onboardingKursID = onboardingKurs[0].id.toString();
			// Check if onboarding is done
			if (!this.doneKurse.includes(this.onboardingKursID))
				onboarding = onboardingKurs[0];

			// Advorialert Kurs Logic
			// Get advertorial ids
			let advertorialIDs = [];
			advertorialKurse.forEach((kurs) =>
				advertorialIDs.push(kurs.id.toString())
			);
			// Filter out done advertorials
			let advertorial = null;
			let notDoneAdvertorials = advertorialIDs.filter((advertorialId) => {
				return !this.doneKurse.includes(
					advertorialId || this.$store.state.lastAdvertorial
				);
			});
			// Get a random Advertorial
			const advertorialID =
				notDoneAdvertorials[
					Math.floor(Math.random() * notDoneAdvertorials.length)
				];

			if (advertorialID) {
				this.$store.state.lastAdvertorial = advertorialID;
				advertorial = advertorialKurse.find(
					(advertorial) => advertorial.id.toString() === advertorialID
				);
			}

			// Get right amount of kurse depending on onboarding and advertorial state
			let kurse = [];
			if (onboarding && advertorial)
				if (startedKurse.length > 0)
					kurse.push(startedKurse.slice(0, 1)[0]);
				else kurse.push(newestKurse.slice(0, 1)[0]);
			else if (!onboarding && !advertorial) {
				let started = startedKurse.slice(0, 2);
				kurse.push(...started);
				let newest = newestKurse.slice(0, 3 - started.length);
				kurse.push(...newest);
			} else {
				let started = startedKurse.slice(0, 1);
				let newest = newestKurse.slice(0, 2 - started.length);
				kurse.push(...started);
				kurse.push(...newest);
			}

			// Combine results to kurs array
			if (onboarding) this.allKurse.push(onboarding);
			this.allKurse.push(...kurse);
			// check if we have an advertorial
			if (advertorial && !this.hasAdvertorial(this.allKurse))
				this.allKurse.push(advertorial);

			// remove duplicates
			// a shortcut to deal with double advertorials
			this.allKurse = this.removeDuplicatesById(this.allKurse);

			// add a kurs if we have less then 3
			if (this.allKurse.length < 3) {
				// console.log(this.allKurse);
				const unusedNewest = newestKurse.find(
					(obj1) => !this.allKurse.some((obj2) => obj2.id === obj1.id)
				);
				this.allKurse.splice(1, 0, unusedNewest);
				this.allKurse.reverse();
			}
		},
		hasAdvertorial(array) {
			return array.find((item) => item.advertorial);
		},
		removeDuplicatesById(array) {
			const seen = new Set();
			return array.filter((item) => {
				const isNotDuplicate = !seen.has(item.id);
				seen.add(item.id);
				return isNotDuplicate;
			});
		},
		ionViewDidEnter() {
			setTimeout(() => {
				this.$store.dispatch('updateLoading', false);
			}, 2000);
		},
	},
};
</script>

<style scoped lang="scss">
ion-header ion-img {
	width: 160px;
	margin: 0 auto;
	margin-top: 5px;
}

ion-header ion-img::part(image) {
	margin-left: -20px;
}
#greeting {
	background: url(/assets/img/dashbg.jpg);
	height: 300px;
	background-position: top center;
	background-repeat: no-repeat;
	background-size: cover;
	position: relative;
	margin-bottom: 20px;

	.toolbar-title {
		font-size: 20px;
	}

	.container {
		background-color: rgba(255, 255, 255, 0.8);
		position: absolute;
		bottom: 0;
		left: 0;
		height: 130px;
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 2rem;
		h1 {
			font-weight: bold;
		}
	}
}

ion-content {
	--padding-bottom: 40px;
	.container {
		padding-bottom: 0px;
		padding-top: 0px;
		.divider {
			width: 80px;
			height: 5px;
			background: #4a4a4a;
		}
		h2 {
			font-size: 20px;
		}
	}
	.newscontainer {
		display: flex;

		.card {
			width: 50%;
		}
	}
}
</style>
